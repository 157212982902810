<template>
  <base-layout :title="userName" page-default-back-link="/tabs/users">
    <h1>{{user.name}}</h1> 
     <ion-content text-center v-if="user">
         <ion-grid>
             <ion-row>
                 <ion-col offset-md="2" size-md="8" offset-lg="4" size-lg="4" offset-sm="0" size-sm="12">
                    <ion-card class="ion-text-center">
                      <ion-avatar style="margin-left: auto; margin-right: auto">
                        <img :src="user.photoURL" :alt="user.name" width="60" />
                      </ion-avatar>
                      <ion-item>
                        <ion-label color="primary">Admin</ion-label>
                        <ion-toggle v-model="user.admin">
                        </ion-toggle>
                      </ion-item>
                        <ion-item>
                        <ion-label color="primary">Barista</ion-label>
                        <ion-toggle v-model="user.barista">
                        </ion-toggle>
                      </ion-item>
                      <ion-button @click="saveUser">Save</ion-button>
                    </ion-card>
                 </ion-col>
             </ion-row>
         </ion-grid>
     </ion-content>
  </base-layout>
</template>

<script>
//import {IonToggle, IonLabel, IonItem} from "@ionic/vue"
export default  {
  components: {
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  computed: {
    user() {
      return this.$store.getters.findUser(this.id);
    },
    userName() {
      return this.loggedInUser ? this.loggedInUser.name : "";
    }
  },
  methods: {
    saveUser() {
      this.$store.dispatch("saveUser", this.user);
    }
  },
  mounted() {
    if (this.users == null) {
      this.$store.dispatch("loadUsers");
    }
  }
};
</script>